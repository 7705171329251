import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import independence from '../assets/images/independence.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Our Philosophy</title>
            <meta name="description" content="Our Philosophy: The Declaration of Independence of Cyberspace" />
        </Helmet>

        <div id="main" className="alt">
            <section id="philosophy">
                <div className="inner">
                    <header className="major">
                        <h1>Our Philosophy</h1>
                    </header>
                    <span className="image main"><img src={independence} alt="" /></span>
                    <p>Our role as technology creators is to weave together software applications in ways that are valued and useful to end-users.</p>
                    <p>We believe open peer-to-peer networks play a crucial role in the peaceful scalability of our societies.</p>
                    <p>Satoshi's innovation arrives just in time as much of our businesses and social interactions move to digital marketplaces. We promote, educate, design, implement, and help operate crypto networks in the hope of bringing about more peaceful collaboration among peoples. We share John Perry Barlow's vision for the <a href="https://www.eff.org/cyberspace-independence" target="other">Declaration of the Independence of Cyberspace.</a></p>

                    <h2 id="mission"> Our Mission: P2P Privacy, Decentralized Security, and Trustless Collaboration</h2>
                    <p>We design and build disruptive decentralized consensus protocols. We promote the use of peer-to-peer networks as a mean to regain the privacy, security, and liberty that anyone should expect in their online affairs. Centralization enabled economies of scale, but also created opportunity for greed, fraud, and corruption.</p>
                    <p>Blockchain technology provides a balancing force that enables collaboration in a *decentralized* fashion. Properly designed peer-to-peer protocols *replace* the need to build trust between network participants with a series of incentives and fees pre-established in the open protocol specifications. This removes opportunities for fraud and rent-seeking while providing superior service and guarantees.</p>
                </div>
            </section>
        </div>

    </Layout>
)

export default Generic
